/* General container styling For project */
.login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #1A202C;
    color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

/* Light mode container */
body.light-mode .login-container {
    background-color: #FFFFFF; /* White background for light mode */
    color: #333333; /* Dark text for light mode */
    border: 1px solid #CCCCCC; /* Light border */
}

h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: white;
    transition: color 0.3s ease;
}

/* Light mode heading */
body.light-mode h1 {
    color: #333333; /* Dark text for light mode */
}

/* Form group styling */
.form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 5px;
    color: #CBD5E0;
    transition: color 0.3s ease;
}

/* Light mode label */
body.light-mode label {
    color: #333333; /* Dark text for light mode */
}

input {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #CBD5E0;
    background-color: #2D3748;
    color: white;
    outline: none;
    transition: all 0.3s ease;
}

/* Light mode input fields */
body.light-mode input {
    background-color: #FFFFFF; /* White background */
    color: #333333; /* Dark text */
    border: 1px solid #333333; /* Dark border */
}

input::placeholder {
    color: #A0AEC0;
    transition: color 0.3s ease;
}

/* Light mode placeholder */
body.light-mode input::placeholder {
    color: #666666; /* Darker placeholder for light mode */
}

/* Button styling */
button {
    width: 100%;
    padding: 10px;
    background-color: #3182CE; /* Default blue button */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Light mode button */
body.light-mode button {
    background-color: #570000; /* Dark red for light mode */
    color: #FDF5E6; /* Cream text for button in light mode */
}

button:hover {
    background-color: #2B6CB0; /* Darker blue for hover */
}

/* Light mode hover */
body.light-mode button:hover {
    background-color: #A52A2A; /* Hover red for light mode */
}
