/* General body styling for the page background */
body {
    background-color: #20262E;
    color: white;
    margin: 0;
    font-family: Arial, sans-serif;
    transition: all 0.3s ease; /* Smooth transitions for theme changes */
}

/* Light mode styling for body */
body.light-mode {
    background-color: #FDF5E6; /* Cream/off-white background */
    color: #333333; /* Dark text */
}

/* Header styling */
header {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #20262E;
    border-bottom: 1px solid #2D3748;
    transition: all 0.3s ease;
}

/* Light mode header */
body.light-mode header {
    background-color: #FDF5E6 !important; /* Light gray for header background */
    color: #333333; /* Dark text */
    border-bottom: 1px solid #FDF5E6; /* Light border */
}

header .logo {
    font-size: 24px;
    font-weight: bold;
    color: white;
}

body.light-mode header .logo {
    color: #333333; /* Dark text */
}

header .nav {
    display: flex;
}

header .nav a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

body.light-mode header .nav a {
    color: #333333; /* Dark text */
}

header .nav a:hover, header .nav a.active {
    background-color: #3182CE;
    color: white;
}

body.light-mode header .nav a:hover, body.light-mode header .nav a.active {
    background-color: #570000; /* Dark red for hover */
    color: #FDF5E6; /* Light text on hover */
}

/* About Us section */
.about-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 0 20px;
}

h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: white;
}

body.light-mode h1 {
    color: #333333; /* Dark text for light mode */
}

h2 {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 10px;
    color: white;
}

body.light-mode h2 {
    color: #333333; /* Dark text for light mode */
}

p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #CBD5E0;
}

body.light-mode p {
    color: #333333; /* Dark text for light mode */
}

.about-image {
    width: 300px;        /* Set desired width */
    height: auto;        /* Maintain aspect ratio */
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-image-wrapper {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}
