/* src/BillList.css */

/* Base styles for the container */
.bill-list-container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
  height: 80vh;
  overflow: scroll;
}

/* Light mode styles */
.bill-list-container.light {
  background-color: #ffffff;
  color: #333333;
}

.bill-list-container.light .bill-info span {
  color: #555555;
}

/* Dark mode styles */
.bill-list-container.dark {
  background-color: #2b2b2b;
  color: #dddddd;
}

.bill-list-container.dark .bill-info span {
  color: #aaaaaa;
}

.bill-list-container h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  transition: color 0.3s ease;
}

/* Common styles for list */
.bill-list-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bill-list-container li {
  padding: 1rem;
  border-bottom: 1px solid #eaeaea;
  transition: background-color 0.3s ease;
}

.bill-list-container.light li:hover {
  background-color: #f9f9f9;
}

.bill-list-container.dark li:hover {
  background-color: #444444;
}

.bill-list-container li:last-child {
  border-bottom: none;
}

.bill-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.bill-info span {
  flex: 1;
  text-align: center;
  transition: color 0.3s ease;
}
