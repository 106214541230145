/* src/Header.css */

/* Header container */
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #20262E;
    color: white;
}

/* Left section of the header */
.header-left {
    display: flex;
    align-items: center;
}

/* Container for stacked logo text */
.logo-container {
    display: flex;
    flex-direction: column;  /* Stack text vertically */
    align-items: center;     /* Center align the text */
    margin-right: 20px;      /* Space between text and theme toggle */
}

/* Main heading style (Large Text) */
.main-heading {
    font-size: 32px;         /* Make it large */
    font-weight: bold;
    margin: 0;
    color: #fff;             /* White color for main heading */
}

/* Subheading style (Small Text Underneath) */
.subheading {
    font-size: 14px;         /* Make it smaller */
    color: #ccc;             /* Lighter color for subheading */
    margin: 0;
}

/* Theme toggle switch */
.theme-toggle {
    margin-left: 10px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #570000; /* Dark red for light mode */
}

input:checked + .slider:before {
    transform: translateX(14px);
}

/* Navigation links */
.nav {
    display: flex;
    align-items: center;
}

.nav-link {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.nav-link:hover, .nav-link.active {
    background-color: #3182CE;
}
