.profile-container {
    max-width: 600px;
    margin: 50px auto;
    text-align: center;
    padding: 20px;
    background-color: #1A202C;
    color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

button {
    padding: 10px 15px;
    background-color: #3182CE;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #2B6CB0;
}
