/* Set the background color for the entire page */
body {
    background-color: #20262E;
    margin: 0;
    font-family: Arial, sans-serif;
}

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 20px;
    width: 100%;
    max-width: 800px;
    margin: auto;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: white;
}

/* Navbar styling */
header {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #20262E;
}

header .logo {
    font-size: 24px;
    color: white;
    font-weight: bold;
}

header .nav {
    display: flex;
}

header .nav a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

header .nav a:hover, header .nav a.active {
    background-color: #3182CE;
}

/* Tabs container */
.tab-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

/* Styling for each button */
.tab-container button {
    background-color: #2D3748;  /* Button background color */
    color: white;
    border: none;
    padding: 15px 30px;  /* Adjust padding for better spacing */
    margin: 0;  /* Remove margins between buttons */
    border-radius: 0;  /* Keep buttons square */
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;  /* Center the text horizontally */
    display: flex;
    align-items: center;  /* Center the text vertically */
    justify-content: center;  /* Center the text horizontally */
    flex-grow: 1;  /* Make buttons grow to fill available space */
    height: 50px;  /* Set a fixed height for buttons */
}

/* Button hover effect */
.tab-container button:hover {
    background-color: #4A5568;
}

/* Active button */
.tab-container .active {
    background-color: #3182CE;
}

/* Form container */
.form-container {
    width: 100%;
    max-width: 500px;
}

.form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

label {
    font-size: 14px;
    margin-right: 20px;
    white-space: nowrap; /* Prevent label wrapping */
}

input, select {
    flex-grow: 1;
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #CBD5E0;
    background-color: #1A202C;
    color: white;
    outline: none;
}

input::placeholder {
    color: #A0AEC0;
}

select {
    appearance: none;
}

/* Button styling */
.inspect-button {
    background-color: #3182CE;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    width: 100%; /* Full-width button */
}

.inspect-button:hover {
    background-color: #2B6CB0;
}

#results-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 700px;
    height: 700px;
    margin-top: 1vw;
}

#results-container textarea {
    width: 100%;
    height: 100%;
    padding: 20px;
    margin: 2px;
    background-color: #2D3748;
    color: white;
    border-radius: 5px;
}

#typeOfAmendment {
    text-align: center;
}
/* Light mode styling */
body.light-mode {
    background-color: #fdf5e6; /* Cream/off-white background */
    color: #333333; /* Dark text color */
}

.main-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* Main container */
.main-container.light {
    background-color: #fdf5e6; /* Cream background */
    color: #333333; /* Dark text */
}

/* Button styling for light mode */
.tab-container button.light {
    background-color: #570000; /* Dark red */
    color: #fdf5e6; /* Light text */
}

.inspect-button.light {
    background-color: #570000; /* Dark red */
    color: #fdf5e6; /* Light text */
}

/* Subheader styling for light mode */
h2.light {
    color: #333333; /* Dark text for subheader */
}

label.light {
    color: #333333; /* Dark label text */
}

input.light, select.light {
    background-color: #fdf5e6; /* Light input background */
    color: #333333; /* Dark text */
    border: 1px solid #333333; /* Dark border */
}

textarea.light {
    background-color: #fdf5e6; /* Light background */
    color: #333333; /* Dark text */
    border: 1px solid #333333; /* Dark border */
}
/* Header styling for light mode */
.header.light {
    color: #333333; /* Dark text for light mode */
}

.header.dark {
    color: white; /* White text for dark mode */
}
.main-container.light .header {
    color: #333333 !important;
}

.main-container.dark .header {
    color: white !important;
}
/* Results container textarea */
#results-container textarea {
    width: 100%;
    height: 100%;
    padding: 20px;
    margin: 2px;
    background-color: #2D3748; /* Dark gray for dark mode */
    color: white;
    border-radius: 5px;
    border: 1px solid #CBD5E0;
    transition: all 0.3s ease;
    pointer-events: none; /* Disable editing */
    overflow: scroll; /* Add scrollbars for long text */
}

#results-container textarea.light {
    background-color: #f5efdc; /* Darker off-white for light mode */
    color: #333333; /* Dark text */
    border: 1px solid #333333; /* Dark border */
}
/* Results container */
#results-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25vh;
    margin-top: 1vw;
    transition: all 0.3s ease; /* Smooth transition for theme changes */
}

/* Results box */
#results-container textarea {
    width: 100%;
    height: 100%;
    padding: 20px;
    margin: 2px;
    background-color: #2D3748; /* Dark background for dark mode */
    color: white; /* Text color for dark mode */
    border-radius: 5px;
    border: 1px solid #CBD5E0; /* Border for dark mode */
    transition: all 0.3s ease;
}

/* Light mode results box */
body.light-mode #results-container textarea {
    background-color: #FFFFFF; /* White background for light mode */
    color: #333333; /* Dark text color for light mode */
    border: 1px solid #333333; /* Dark border for light mode */
}
