/* General body styling for the page background */
body {
    background-color: #20262E;
    color: white;
    margin: 0;
    font-family: Arial, sans-serif;
    transition: all 0.3s ease; /* Smooth transition for theme changes */
}

/* Light mode styling for body */
body.light-mode {
    background-color: #FDF5E6; /* Cream/off-white background */
    color: #333333; /* Dark text */
}

/* Header styling */
header {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #20262E;
    border-bottom: 1px solid #2D3748;
    transition: all 0.3s ease;
}

body.light-mode header {
    background-color: #F5F5F5; /* Light header background */
    color: #333333; /* Dark text for light mode */
    border-bottom: 1px solid #CCCCCC; /* Light border */
}

header .logo {
    font-size: 24px;
    font-weight: bold;
    color: white;
}

body.light-mode header .logo {
    color: #333333; /* Dark text in light mode */
}

header .nav {
    display: flex;
}

header .nav a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

body.light-mode header .nav a {
    color: #333333; /* Dark text in light mode */
}

header .nav a:hover, header .nav a.active {
    background-color: #3182CE;
    color: white;
}

body.light-mode header .nav a:hover, body.light-mode header .nav a.active {
    background-color: #570000; /* Dark red for hover */
    color: #FDF5E6; /* Light text */
}

/* Pricing Section */
.pricing-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 0 20px;
}

h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: white;
}

body.light-mode h1 {
    color: #333333; /* Dark text for light mode */
}

h2 {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 10px;
    color: white;
}

body.light-mode h2 {
    color: #333333; /* Dark text for light mode */
}

p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #CBD5E0;
}

body.light-mode p {
    color: #333333; /* Dark text for light mode */
}

/* Pricing Table */
.pricing-table {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

/* Pricing Card */
.pricing-card {
    background-color: #1A202C;
    border: 1px solid #2D3748;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    text-align: center;
    transition: all 0.3s ease;
}

body.light-mode .pricing-card {
    background-color: #FFFFFF; /* Light card background */
    border: 1px solid #CCCCCC; /* Light border */
    color: #333333; /* Dark text for light mode */
}

.pricing-card h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: white;
}

body.light-mode .pricing-card h3 {
    color: #333333; /* Dark text for light mode */
}

.pricing-card .price {
    font-size: 32px;
    margin-bottom: 20px;
    color: #3182CE;
}

body.light-mode .pricing-card .price {
    color: #570000; /* Dark red for light mode */
}

.pricing-card ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
    color: #CBD5E0;
}

body.light-mode .pricing-card ul {
    color: #333333; /* Dark text for light mode */
}

.pricing-card ul li {
    margin-bottom: 10px;
}

/* Pricing Card Button */
.pricing-card button {
    background-color: #3182CE;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

body.light-mode .pricing-card button {
    background-color: #570000; /* Dark red for light mode */
    color: #FDF5E6; /* Light text */
}

.pricing-card button:hover {
    background-color: #2B6CB0; /* Darker blue for hover */
}

body.light-mode .pricing-card button:hover {
    background-color: #A52A2A; /* Lighter red for hover */
}
