/* General body styling for the page background */
body {
    background-color: #20262E;
    color: white;
    margin: 0;
    font-family: Arial, sans-serif;
    transition: all 0.3s ease; /* Smooth transition for theme changes */
}

/* Light mode styling */
body.light-mode {
    background-color: #FDF5E6; /* Cream/off-white background */
    color: #333333; /* Dark text */
}

/* Header styling */
header {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #20262E;
    border-bottom: 1px solid #2D3748;
    transition: all 0.3s ease;
}

body.light-mode header {
    background-color: #F5F5F5; /* Light header background */
    border-bottom: 1px solid #CCCCCC; /* Light border */
}

header .logo {
    font-size: 24px;
    font-weight: bold;
    color: white;
}

body.light-mode header .logo {
    color: #333333; /* Dark text for light mode */
}

header .nav {
    display: flex;
}

header .nav a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

body.light-mode header .nav a {
    color: #333333; /* Dark text for light mode */
}

header .nav a:hover, header .nav a.active {
    background-color: #3182CE;
}

body.light-mode header .nav a:hover, body.light-mode header .nav a.active {
    background-color: #570000; /* Dark red for hover */
    color: #FDF5E6; /* Light text */
}

/* Contact Section */
.contact-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 0 20px;
}

h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: white;
}

body.light-mode h1 {
    color: #333333; /* Dark text for light mode */
}

p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #CBD5E0;
}

body.light-mode p {
    color: #333333; /* Dark text for light mode */
}

a {
    color: #3182CE;
    text-decoration: none;
}

body.light-mode a {
    color: #570000; /* Dark red for light mode */
}

a:hover {
    text-decoration: underline;
}

/* Form Styling */
form {
    margin-top: 20px;
}

.form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 5px;
    color: #CBD5E0;
}

body.light-mode label {
    color: #333333; /* Dark text for light mode */
}

input, textarea {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #CBD5E0;
    background-color: #1A202C;
    color: white;
    outline: none;
    width: 100%;
    max-width: 300px; /* Set a fixed width */
    transition: all 0.3s ease;
}

body.light-mode input, body.light-mode textarea {
    background-color: #FFFFFF; /* White background for light mode */
    color: #333333; /* Dark text for light mode */
    border: 1px solid #333333; /* Dark border for light mode */
}

input::placeholder, textarea::placeholder {
    color: #A0AEC0;
}

body.light-mode input::placeholder, body.light-mode textarea::placeholder {
    color: #666666; /* Dark placeholder for light mode */
}

button {
    padding: 10px 15px;
    background-color: #3182CE;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: 100px;
    margin-top: 10px;
}

body.light-mode button {
    background-color: #570000; /* Dark red for light mode */
    color: #FDF5E6; /* Light text for button in light mode */
}

button:hover {
    background-color: #2B6CB0;
}

body.light-mode button:hover {
    background-color: #A52A2A; /* Lighter red for hover in light mode */
}
